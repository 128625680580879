import React, { useContext, useEffect } from 'react'
import Button from 'components/Buttons/Button'
import useTimer from 'utils/useTimer'
import { AppContext } from 'utils/context'
import { useTranslation, Trans } from 'react-i18next'
import './style.scss'

const LogoutModalBody = ({ cancelLogout, cancelLoading, setCancelLoading }) => {

  const { t } = useTranslation()
  const { count } = useTimer(60)
  const { handleLogout } = useContext(AppContext)

  useEffect(() => {
    if (count < 1) handleLogout()
  }, [count, handleLogout])

  useEffect(() => {
    // on mount, remove loading spinner
    setCancelLoading(false)
  }, [setCancelLoading])

  return (
    <div className="LogoutModalBody">
      <strong>{t('Are you still there?')}</strong>
      <p>
        {t('logoutTimer', {count})}
      </p>
      <Button id="firstFocus" onClick={cancelLogout} Pill Loading={cancelLoading} DataTestId="cancel-logout">
        {t('Cancel')}
      </Button>
      <Button onClick={handleLogout} Pill DataTestId="logout">
        {t('Log out now')}
      </Button>
    </div>
  )
}

export default LogoutModalBody