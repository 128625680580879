import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'components/Buttons/Button'
import { AccountContext, AppContext, SupportContext } from 'utils/context'
import { useTranslation } from 'react-i18next'

const MultiPageFormNav = ({ id, fs, formData, handleFormErrors, setFieldValue, lastPage, handleSubmit, formLoading }) => {

  const { push } = useHistory()
  const { t } = useTranslation()
  const { setSupportField } = useContext(SupportContext)
  const { setDialogOpen, setPreviouslyFocused } = useContext(AppContext)
  const { currentRequest, updateLineitem, submitting, setSubmitting, policies, setLineitemProvince } = useContext(AccountContext)

  const currentIndex = formData.fieldsets && formData.fieldsets.indexOf(formData.current_fieldset)
  const isFinal = formData.current_fieldset === 'confirm' || formData.current_fieldset === 'completed'

  const go = fs => {
    const { requestType, lineitemUrl } = currentRequest
    push(`/account/requests/${requestType}/${lineitemUrl}/${id}/${fs}`)
  }

  const prev = () => {
    let prevFs = formData.fieldsets[currentIndex - 1]
    if (formData.current_fieldset === 'confirm') {
      prevFs = formData.fieldsets[formData.fieldsets.length - 2]
    }
    setFieldValue('current_fieldset', prevFs)
    go(prevFs)
  }

  const setProvince = () => {
    if (formData['policy']) {
      const policy = policies.find(p => Number(p.id) === Number(formData['policy']))
      if (policy && policy.province) {
        setLineitemProvince(policy.province)
      }
    }
  }

  const next = () => {
    setSubmitting(true)
    const nextFs = formData.fieldsets[currentIndex + 1] === 'completed' ? 'confirm' : formData.fieldsets[currentIndex + 1]
    updateLineitem(formData, fs)
      .then(res => {
        setProvince()
        setFieldValue('current_fieldset', nextFs)
        setSubmitting(false)
        go(nextFs)
      })
      .catch(err => {
        handleFormErrors(err.response.data)
        setSubmitting(false)
      })
  }
  
  const save = () => {
    setSubmitting(true)
    updateLineitem(formData, fs)
      .then(res => {
        setProvince()
        setSubmitting(false)
      })
      .catch(err => {
        handleFormErrors(err.response.data)
        setSubmitting(false)
      })
  }

  const handleHelpClick = e => {
    e.preventDefault()
    setSupportField('')
    setDialogOpen('form-support')
    setPreviouslyFocused('nav-trigger')
  }

  useEffect(() => {
    setFieldValue('current_fieldset', fs)
  }, [fs]) // eslint-disable-line

  return (
    <div className="FormNav RequestNav">
      <div className="container">
        {(currentIndex > 0 || isFinal) &&
          <Button onClick={prev} Pill Light className="BackBtn" DataTestId="back">
            {t('Back')}
          </Button>
        }
        <Button onClick={handleHelpClick} Pill Transparent className="NeedHelp" DataTestId="help">
          {t('Need Help?')}
        </Button>
        {lastPage ? (
          <Button onClick={handleSubmit} Pill Light Loading={submitting || formLoading} DataTestId="submit">
            {t('Submit')}
          </Button>
        ) : (
          <div>
            <Button onClick={save} className="SaveBtn" Pill Light Loading={submitting} DataTestId="Save">
              {t('Save')}
            </Button>
            <Button onClick={next} Pill Light Loading={submitting} DataTestId="continue">
              {t('Continue')}
            </Button>
          </div>  
        )}
      </div>
    </div>
  )
}

export default MultiPageFormNav